
/* eslint-disable */
import { eStatusEnvelope } from '@/services/enums/eStatusEnvelope'
import { ListEnvelope } from '@/services/responses/ListEnvelopeResponse'
import {
  FileTextOutlined,
  FilePdfOutlined,
  EyeOutlined,
  CloseOutlined,
  QuestionCircleOutlined,
  UnorderedListOutlined,
  CheckCircleOutlined,
  InfoCircleOutlined,
  CloseCircleOutlined,
  SolutionOutlined,
  LockOutlined,
  DeleteOutlined,
  ArrowRightOutlined,
  DownloadOutlined
} from '@ant-design/icons-vue'
import { notification } from 'ant-design-vue'
import { defineComponent, ref } from 'vue'
import { mapActions, mapState } from 'vuex'
import { EnvelopeService } from '@/services/EnvelopeService'
import { TokenService } from '@/services/TokenService'
import EnvelopeDetails from '@/views/home/details/index.vue'
import PageTitle from '@/assets/components/PageTitle.vue'
import ChangeCofreByDocument from '@/views/home/folder/changeCofreByDocument.vue'

export default defineComponent({
  name: 'DataTable',
  components: {
    FileTextOutlined,
    FilePdfOutlined,
    EyeOutlined,
    CloseOutlined,
    QuestionCircleOutlined,
    UnorderedListOutlined,
    CheckCircleOutlined,
    InfoCircleOutlined,
    CloseCircleOutlined,
    SolutionOutlined,
    LockOutlined,
    DeleteOutlined,
    ArrowRightOutlined,
    DownloadOutlined,
    EnvelopeDetails,    
    PageTitle,
    ChangeCofreByDocument
  },
  computed: {
    ...mapState('sections', ['envelopes', 'currentStatus', 'folderName']),
    envelops (): ListEnvelope[] {
      return this.envelopes !== null ? this.envelopes?.data : []
    }
  },
  data: () => ({
    visible: ref<boolean>(false),
    columns: [
      {
        title: 'Título',
        dataIndex: 'title',
        key: 'title',
        slots: { customRender: 'name' },
        fixed: 'center',
        resizable: true,
        width: 20,
        ellipsis: true
      },
      {
        title: 'Data',
        dataIndex: 'date',
        key: 'date',
        width: 8
      },
      {
        title: 'Progresso',
        key: 'progress',
        slots: { customRender: 'progress' },
        width: 15
      },
      {
        title: '',
        key: 'action',
        slots: { customRender: 'action' },
        width: 7
      }
    ],
    isShowDetailsDocument: ref<boolean>(false),
    isShowMoveDocument: ref<boolean>(false),
    detailsDocument: ref<any>([]),    
    hashIdDocument: ref<any>(null),
    infoDocument: ref<any>(null)
  }),
  methods: {
    ...mapActions('sections', ['fetchEnvelopesByVault', 'fetchEnvelopesByUser', 'setOpenNewDocument']),
    getTeste (t: string) {
      return 'Teste'
    },
    handleResizeColumn (w: any, col: any) {
      col.width = w
    },
    progressColor (item: any) {
      const result = this.progressBar(item)
      if (item.status === eStatusEnvelope.CANCELED) return 'exception'
      if (item.status === eStatusEnvelope.PENDING) return 'active'
      if (result === 100) {
        return ''
      }
    },
    documentCanceled (item: any) {
      if (item.status === eStatusEnvelope.CANCELED) return false
      return true
    },
    progressBar (item: any): number {
      return parseFloat(
        ((item.startProgress / item.endProgress) * 100).toFixed(2)
      )
    },
    viewerDocument (item: ListEnvelope) {
      const url = `${process.env.VUE_APP_ROOT_VIEWER}/${
        item.hashId
      }?key=${TokenService.getToken()}&user=true`
      window.open(url, '_self')?.focus()
    },
    clearSpecialCharacters (text: any) {
      let noSpecials = text.replace(/[^\w\d\sáéíóúãõâêîôûàèìòù]/gi, " ")
	  let noMultipleSpaces = noSpecials.replace(/\s+/g, "_")
	  let uniqUnderscore = noMultipleSpaces.replace(/_+/g, "_")
	  return uniqUnderscore
    },
    async downDocument(item: ListEnvelope) {
      let hashId = item.hashId
      let responseEnvelope = await EnvelopeService.getInstance().getEnvelopeByHash(hashId)
      console.log(responseEnvelope)
 
      const res = await fetch(responseEnvelope.archive, 
        {
          mode: "cors",
          method: "GET",
          cache: "force-cache",
          headers: {
          Accept: "application/pdf",
        },
      })

      if (res.status === 200) {
        const blob = await res.blob()
        const a = document.createElement("a")
        a.style.display = "none"
        document.body.appendChild(a)
        a.href = window.URL.createObjectURL(blob)

        const fileName = this.clearSpecialCharacters(responseEnvelope.title)
        
        a.setAttribute("download", fileName)
        a.click()
        
        window.URL.revokeObjectURL(a.href)
        document.body.removeChild(a)
      } else {
        notification.error({
          message: 'Falha de Download',
          description: `Documento indisponível no momento. Por favor, entre em contato com o suporte para obter ajuda!`
        })
      }
    },
    showChangeVaultDocument (record: ListEnvelope) {
      this.infoDocument = record
      this.isShowMoveDocument = true
    },
    titleStatus (item: any) {
      if (item?.status == 1 && item.partSigned) {
        return "Pendente"
      }
      if (item?.status == 1 ) {
        return "Pendente"
      }
      if (item?.status == 2) {
        return "Assinado"
      }
      if (item?.status == 3) {
        return "Cancelado"
      }
      if (item?.status == 5) {
        return "Observador"
      }
      return "Recusado"
    },
    closeModalDetails () {
      this.isShowDetailsDocument = false
    },
    closeModalMoveDocument () {
      this.isShowMoveDocument = false
    },
    async viewerDetailsDocument (record: ListEnvelope) {
      try {
        this.hashIdDocument = record.hashId
        this.detailsDocument = await EnvelopeService.getInstance().detailsEnvelope(record.hashId)
        this.isShowDetailsDocument = true
      } catch (err) {}
    },
    async canceled (record: ListEnvelope) {
      try {
        await EnvelopeService.getInstance().cancelEnvelope(record.id)

        notification.success({
          message: 'Documento Cancelado',
          description: `O documento: ${record.title} foi cancelado.`
        })

        await this.fetchEnvelopesByVault({
          folderId: record.folderId,
          page: 1,
          size: 9
        })
      } catch (err) {}
    },
    async removed (record: ListEnvelope) {
      try {
        await EnvelopeService.getInstance().removeEnvelope(record.id)
        
        notification.success({
          message: 'Documento Removido',
          description: `O documento: ${record.title} foi removido.`
        })

        if (this.currentStatus == null) {
          await this.fetchEnvelopesByVault({
            folderId: record.folderId,
            page: 1,
            size: 9
          })
        } else {
            await this.fetchEnvelopesByUser({
              page: 1,
              size: 9, 
              status: this.currentStatus
            })
        }        
      } catch (err) {} 
    },
  }
})
